import "./Login.css";

import { Formik, FormikErrors, useField, useFormikContext } from "formik";
import { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { login } from "~/util/AccountClient";

import * as Icons from "./Icons";
import { Box } from "./Box";
import { Button } from "./Button";
import {
  FormField,
  FormFieldHeader,
  FormFieldMessage,
  FormFieldValidationMessage,
} from "./FormField";
import { FormStatus } from "./FormStatus";
import { Divider } from "./Page";
import { Panel } from "./Panel";
import { TextInput } from "./TextInput";
import { Heading } from "./Typography";

export const Login = () => {
  return (
    <Box align="center" gap={8}>
      <Panel className="login-panel">
        <LoginHeader />
        <Divider />
        <LoginForm />
      </Panel>
      <Link className="text-md opacity-50" to="/signup">
        {"Don't have an account yet?"}
      </Link>
    </Box>
  );
};

const LoginHeader = () => (
  <Heading level={3}>
    <Box direction="row" justify="center" align="center" gap={2}>
      <Icons.Logo />
      Login
    </Box>
  </Heading>
);

type Values = {
  email: string;
};

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const onSubmit = async (values: Values) => {
    login(values);
    setEmail(values.email);
    setSubmitted(true);
  };

  return !submitted ? (
    <Formik
      initialValues={{ email: location?.state?.email ?? "" }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} gap={3}>
          <LoginFormEmailField />
          <LoginFormSubmitButton />
          <FormStatus />
        </Box>
      )}
    </Formik>
  ) : (
    <Navigate to={"/code"} state={{ from, email }} />
  );
};

let LoginFormEmailField = () => {
  let [field] = useField("email");

  return (
    <FormField>
      <FormFieldHeader>
        <FormFieldMessage>
          <FormFieldValidationMessage name={field.name} />
        </FormFieldMessage>
      </FormFieldHeader>
      <TextInput {...field} placeholder="Email" autoFocus />
    </FormField>
  );
};

let LoginFormSubmitButton = () => {
  let { isSubmitting } = useFormikContext();

  return (
    <Button type="submit" variant="primary" loading={isSubmitting}>
      Request Code
    </Button>
  );
};

function validate(values: Values) {
  let errors: FormikErrors<Values> = {};

  if (!values.email || values.email === "") errors.email = "Email is required.";
  if (!/\S+@\S+\.\S{2,}/.test(values.email))
    errors.email = "Email must be a valid email address.";

  return errors;
}
