import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
  useRef,
  PointerEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import * as Client from "~/store/bundles/Client";
import { useAccount } from "~/context/AuthContext";
import { useConfig, BoardType } from "~/context/ConfigContext";
import { getLastUnread, getLastReadReceipt } from "~/util/AnnouncementClient";

import * as Board from "../store/bundles/Board";
import { getVisitData } from "./user";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const useWindowEventListener = (
  event: any,
  handler: any,
  capture: any
) => {
  useEffect(() => {
    window.addEventListener(event, handler, capture);
    return () => window.removeEventListener(event, handler);
  }, [event, handler, capture]);
};

export const useWindowClickEvent = (handler: any, capture = false) =>
  useWindowEventListener("click", handler, capture);

export const useTitle = (title: string) => {
  useEffect(() => {
    document.title = title ?? "Sticky Studio";
    return () => {
      document.title = "Sticky Studio";
    };
  }, [title]);
};

export const useScrollLock = () => {
  useEffect(() => {
    document.body.classList.add("scroll-lock");
    document.documentElement.classList.add("scroll-lock");

    return () => {
      document.body.classList.remove("scroll-lock");
      document.documentElement.classList.remove("scroll-lock");
    };
  }, []);
};

export const useOrientation = (): "landscape" | "portrait" => {
  const [contentRect, setContentRect] = useState<{
      height: number;
      width: number;
    }>({ height: 0, width: 0 }),
    orientation = useMemo(
      () =>
        contentRect.width >= contentRect.height ? "landscape" : "portrait",
      [contentRect]
    );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setContentRect({
        height: entries[0].contentRect.height,
        width: entries[0].contentRect.width,
      });
    });

    resizeObserver.observe(document.querySelector("#root") as Element);

    return () => resizeObserver.disconnect();
  }, []);

  return orientation;
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useBoardPath = () => {
  const config = useConfig();
  const boardId = useSelector(Board.getBoardId);

  if (config.type === BoardType.Template) {
    return config.websocket
      ? `/template/${boardId}/edit`
      : `/template/${boardId}`;
  } else if (config.type === BoardType.Sandbox) {
    return `/try`;
  } else {
    return `/${boardId}`;
  }
};

export const useBoardId = () => {
  const boardId = useSelector(Board.getBoardId);
  return boardId;
};

export const useBoardPlan = () => {
  return useSelector(Board.getPlan);
};

export const useShowAnnouncement = () => {
  let [announcement, setAnnouncement] = useState(() => getLastUnread());
  let receipt = getLastReadReceipt();

  useEffect(() => {
    setAnnouncement(() => getLastUnread());
  }, [receipt]);

  return announcement;
};

export const useHandleClickDrag = (
  handler: (event: PointerEvent, isDrag: boolean) => void
) => {
  const isDrag = useRef(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const onPointerDown = useCallback(
    (event: PointerEvent) => {
      isDrag.current = false;

      timeout.current = setTimeout(() => {
        handler(event, true);

        isDrag.current = true;
      }, 200);
    },
    [handler]
  );

  const onPointerUp = useCallback(
    (event: PointerEvent) => {
      if (isDrag.current) return;

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      handler(event, false);
    },
    [handler]
  );

  return {
    onPointerDown,
    onPointerUp,
  };
};

/**
 * Automatically zoom to fit the current selection or
 * board on load
 */
export const useZoomFit = () => {
  const dispatch = useDispatch();
  const initialSelection = useRef(useSelector(Client.getSelected));

  useEffect(() => {
    if (initialSelection.current.length > 0) {
      dispatch(Client.zoomToFitSelection());
    } else {
      dispatch(Client.zoomToFitBoard());
    }
  }, [dispatch]);
};

/**
 * In some mobile browsers the viewport height is taller than
 * the visible part of the page. This sets a --vh css var that
 * accurately reflects the visible viewport height and can be
 * used instead.
 */
export const useSetVisibleViewportHeight = () => {
  const setVisibleViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useWindowEventListener("click", setVisibleViewportHeight, false);
  setVisibleViewportHeight();
};

/**
 * Redirect to the onboarding tour or announcement modal
 * routes, but not both, prioritizing the tour.
 */
export const useShowIntroModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const boardPath = useBoardPath();
  const account = useAccount();

  const path = location.pathname;
  const { isFirstVisit, minutesSinceLastVisit } = getVisitData();

  const announcement = useShowAnnouncement();

  const showTour = isFirstVisit && path === boardPath;
  const showAnnouncement =
    announcement &&
    (account || minutesSinceLastVisit >= 5) &&
    path !== `${boardPath}/tour`;

  useEffect(() => {
    if (showTour) {
      navigate(`${boardPath}/tour`, { replace: true });
    } else if (showAnnouncement) {
      navigate(`${boardPath}/announce/${announcement.slug}`, { replace: true });
    }
  }, [navigate, announcement, boardPath, showTour, showAnnouncement]);
};

export const useHasReachedBoardLimit = () => {
  const account = useAccount();

  if (!account) return true;
  if (account.pro) return false;

  const { meta } = account;

  // A value of null means the user has no limit
  if (meta.maxBoards === null) return false;
  return meta.boards >= meta.maxBoards;
};
