import "./TemplateViewer.css";

import React, { useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { NotFound as FourOhFour } from "~/components/404";
import { useAuth } from "~/context/AuthContext";
import { BoardType, Permission } from "~/context/ConfigContext";
import { createAppState } from "~/store";
import { useGetFullBoard } from "~/util/BoardClient";
import { useSetVisibleViewportHeight } from "~/util/hooks";
import uid from "~/util/uid";

import { BoardWithProviders } from "./Board";
import { Box } from "./Box";
import { Button } from "./Button";
import { ConvertToBoardModal } from "./ConvertToBoardModal";

type Props = {
  boardId: string;
};

const TemplateViewer = React.memo(({ boardId }: Props) => {
  const { account } = useAuth();
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();
  const response = useGetFullBoard(boardId);
  const startCreating = () => setCreating(true);
  const finishCreating = () => setCreating(false);
  const userId = useMemo(uid, []);

  // Set the visible viewport height so it can be used to
  // ensure the buttons aren't covered by the browser bar.
  useSetVisibleViewportHeight();

  if (response.status === "loading") return null;
  if (response.status === "failed") return <FourOhFour />;
  if (!response.data.template) return <Navigate to={`/${boardId}`} />;

  const {
    data: {
      name,
      description,
      objects,
      createdBy,
      template,
      comments,
      auth,
      font,
      plan,
    },
  } = response;

  const initialState = createAppState({
    userId: auth.userId ?? userId,
    board: {
      boardId,
      name,
      description,
      createdBy,
      template,
      font,
      plan,
    },
    objects,
    comments,
  });

  const config = {
    userId: auth.userId ?? userId,
    type: BoardType.Template,
    permission: Permission.ReadOnly,
    websocket: false,
    boardId,
  };

  const startEditing = () => {
    navigate("/template/" + boardId + "/edit");
  };

  const onCreateBoard = (boardId: string) => {
    navigate(`/${boardId}`);
  };

  let isAuthenticated = !!account;

  return (
    <Box>
      {isAuthenticated && (
        <TemplateButtons
          isCreator={account?.id === createdBy}
          onEdit={startEditing}
          onCreate={startCreating}
        />
      )}
      <BoardWithProviders config={config} initialState={initialState} />
      <ConvertToBoardModal
        open={creating}
        initialState={initialState}
        onClose={finishCreating}
        onCreate={onCreateBoard}
      />
    </Box>
  );
});

type TemplateButtonsProps = {
  isCreator: boolean;
  onEdit(): void;
  onCreate(): void;
};

let TemplateButtons = ({
  isCreator,
  onEdit,
  onCreate,
}: TemplateButtonsProps) => (
  <Box
    gap={2}
    direction="row"
    justify="center"
    className="template-viewer-buttons"
  >
    <Button variant="primary" onClick={onCreate}>
      Use template
    </Button>
    {isCreator && <Button onClick={onEdit}>Edit template</Button>}
  </Box>
);

export const TemplateViewerWrapper = () => {
  const { boardId } = useParams();
  if (!boardId) return null;

  return <TemplateViewer boardId={boardId} />;
};
